.add-modal{
	.col-padd{
		padding: 0 5px;
	}
	.modal-header{
		padding:0 15px;
		background-color: #f8f9fb;
		border-bottom: 1px solid #F5F6F5;
		h3{
			font-size:14px;
			color: #9facbd;
		}
		h4{
			font-size:14px;
			font-weight: bold;
			color: $dark-blue;
		}
		.modal-task-name{
			padding-left: 10px;
		}
	}
	.form-label{
		font-size:14px;
		color: #9facbd;
		padding-left: 10px;
		margin-bottom: 0.2rem;
	}
	.name-input{
		width: 200px
	}
	input{
		background-color: transparent;
		border: none;
		font-size: 14px;
		color: $dark-blue;
		font-weight: 700;
	}
	.modal-body{
		background-color: #ffffff;
		border: none;
		padding: 0 15px;
		&__colon-border{
			border-left:1px solid #D9D9D9;
		}
		.form-group{
			margin:  0;
			.css-bg1rzq-control{
				border: none;
				background-color: transparent;
				min-height: 30px;
				
			}
			.css-bgvzuu-indicatorSeparator{
				display: none;
			}
			.css-151xaom-placeholder{
				font-size: 14px;
				color: $dark-blue;
				font-weight: 700;
			}
		}
		
		textarea{
			border: none!important;
			font-size: 14px;
			color: $dark-blue;
			font-weight: 700;
		}
		.btn{
			width: 100%;
			height: 100px;
			border-radius: 0;
		}
		&-odd{
			background-color: #ffffff;
		}
		&-even{
			background-color: #f8f9fb;
		}
		.modal-drop ,aside{
			display: block;
			text-align: center;
			color: $secondary-color;
			margin-top: 3rem;
			.material-icons{
				font-size:50px;
			}
			.media-body{
				padding: 10px;
			}
		}
		.custom-control-label{
			font-size: 14px;
			color: #9FACBD;
			font-weight: 700;
			&::before{
				border: 2px solid #8998AC;
				box-shadow: 0px 2px 3px 0px rgba(59,72,89,0.6);
			}
		}
		.col-padd{
			margin: 10px 0;
		}
	}
	.modal-content{
		background-color: transparent;
		border: none;
	}
	.modal-footer{
		justify-content: flex-start;
		border: none;
	}
	@media (max-width:991.95px){
		.modal-drop{
			margin-top: 0!important;
		}
	}
	@media (max-width:1199.95px){
		.modal-body-even{
			.order-5{
				order:1;
			}
		}
	}
	@media (min-width: 992px){
		.modal-lg, .modal-xl {
			max-width: 1050px;
		}
	}
}
