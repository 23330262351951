.submit-wrapper {
	
	button {
		box-shadow: 0 10px 20px rgba(59, 124, 255, .2);
		font-size: 0.75rem;
		width: 180px;
		height: 45px;
		white-space: nowrap;
	}
	
	.save {
		background-color: #ED2224;
		border-color: #ED2224;
		margin-right: 1rem;

		&:disabled, &:disabled:hover {
			background-color: #bdc3c7;
			border-color: #bdc3c7;
			box-shadow: none;
		}

		&:hover, &:focus, &:active {
			background-color: #ED2224 ;
			border-color: #ED2224;
			box-shadow: none;
		}
	}

	.cancel {
		background-color: #F36F6F;
		border-color: #F36F6F;
		
	
		&:hover, &:focus, &:active {
			background-color: #F36F6F;
			border-color: #F36F6F;
			box-shadow: none;
		}
	}
}
