.dashboard-header{
	padding: 10px 15px;
	background-color: #fff;
	.sidebar-toggle{
		padding: 0;
		vertical-align: middle;
		img{
			width: 16px;
			height: 14px;
		}
	}
	.navbar-right {
		.notifications-btn{
			padding: 0;
		}
		
	}
	.asideOpen{
		transform: rotate(0deg);
		transition: all .25s ease-out;
	}
	.asideOpen:focus{
		box-shadow: none;
	}
	.asideClose{
		transform: rotate(180deg);
		transition: all .25s ease-out;
	}
	.asideClose:focus{
		box-shadow: none;
	}
	.dropdown-toggle::after{
		display: none;
	}
	.dropdown-item:active ,.dropdown-item:hover {
		background-color: transparent;
		color: #000;
	}
	.down{
		margin-top: 4px;
		.dropdown-toggle{
			border-radius: 50%;
			padding: 1px;
			width: 30px;
			height: 30px;
			margin-right:32px;
			box-shadow: 0 40px 70px rgba($light-blue, 0.2);
			}
		.dropdown-menu{
			left: -60px;
			border: none;
			background-color: transparent;
		}
		.dropdown-item{
			width: 112px;
			margin-top: 20px;
		}
		
	}
	.down-notification{
		margin-right: 32px;
		margin-top: 5px;
		.btn:focus, .btn:active,.btn:active:focus{
			box-shadow: none;
			color: #000;
			background-color: transparent;
			border: none;
		}
		.notific-icon {
			background-color: transparent;
			color: black;
			border: none;
		}
		.notification-content{
			float: none;
			right: -105px;
		}
		.dropdown-menu{
			border: none;
			background-color: transparent;
		}
		.dropdown-menu-lg{
			min-width: 19rem;
		}
		.list-group-item{
			background-color: $light-gray;
		}
	}
	
	.profile-picture-wrapper{
		div,span{
			vertical-align: baseline;
			padding: 0 5px;
			display: inline-block;
		}

		svg,img{
			height: 35px;
			width: auto;
		}
	}
}
