.row-margin{
	margin: 10px 2px 10px 3px;
	.col-padd{
		padding: 0 5px;
	}
	.head-news{
		background-color: #ffffff;
		padding: 20px 8px 20px 46px;
		h2{
			font-size: 15px;
			font-weight: 600;
			color: $dark-blue;
		}
		span{
			color: #c8d0d9;
		}
		.material-icons{
			font-size: 29px;
			vertical-align: middle;
			color: #9facbd;
			margin-right: 17px;
		}
		a:hover{
			text-decoration: none;
		}
	}
	.news-scroll{
		padding: 10px 0 22px 0;
		height: 430px;
		overflow-y: auto;
	
	}
	.news-scroller{
		padding: 10px 10px 22px 10px;
		height: 65vh;
		overflow-y: auto
	}
	.news-text{
		p{
			font-size: 12px;
			color: #9facbd;
			margin-bottom:7px;
		}
		h3{
			color: $dark-blue;
			font-size: 14px;
			margin-bottom: 0px;
		}
		.media{
			margin:0 10px 10px 14px;
			padding-bottom: 20px;
			border-bottom: 1px solid #f5f6f5;
		}
		.media-body{
			margin-left: 23px;
			
		}
		.btn-danger{
			margin-top: 15px;
		}
		@media (max-width:425px) {
			.media{
				display: inline-block;
				margin-top: 10px;
				text-align: center;
			}
			.media-body{
				margin-top: 10px;
				text-align: left;
			}
			
		}
	}
	.task-text{
		p{
			font-size: 12px;
			color: #9facbd;
			margin-bottom:7px;
		}
		h3{
			color: $dark-blue;
			font-size: 14px;
			margin-bottom: 0px;
		}
		.media{
			margin:0 10px 10px 14px;
			padding-bottom: 20px;
			border-bottom: 1px solid #f5f6f5;
		}
		.media-body{
			margin-left: 23px;
			
		}
		@media (max-width:425px) {
			padding: 0px;
		}

		
	}
	.card{
		border-radius: 15px;
		
		.card-header{
			border-top-right-radius: 15px;
			border-top-left-radius: 15px;
		}
	}
	.btn-edit{
		button{
			margin-bottom: 17px;
			border:#9facbd;
		}
		.material-icons{
			font-size: 17px;
			vertical-align: text-top;
			color: #ffffff;
		}
	}
	.person{
		padding: 0 5px;
		margin-bottom: 20px;
		.card{
			height: 100px;
		}
		.material-icons{
			font-size: 50px;
			color: #9facbd;
			padding: 5px 5px 17px 0;
		}
		.media-body{
			margin: auto;
		}
		h3{
			font-size:18px;
			font-weight: 600;
			color: #3b7cff;
			margin-bottom: 0;
		}
		p{
			font-size:12px;
			color: #9facbd;
		}
	}
	::-webkit-scrollbar{
		width: 10px;
		height:10px;
	}
	::-webkit-scrollbar-thumb {
		background: #f5f8fc; 
		border-radius: 15px;
	}
	.day-off-text{
		h3{
			font-size: 16px;
			font-weight: 600;
			color: $dark-blue;
			margin-bottom: 0px;
		}
		p{
			font-size: 12px;
			color: #9facbd;
			margin-bottom:0px;
		}		
		.days-date{
			color: #3b7cff;
		}
		.btn{
			border: none;
			.material-icons{
				font-size: 17px;
				vertical-align:text-top;
				font-weight: bolder;
			}
		}
		.media{
			margin: 0 0px 8px 0px;
			border-bottom: 1px solid #f5f6f5;
			padding-bottom:9px;
		}
		.media-body{
			display: flex;
		}
		@media (max-width: 1596.98px) {
			.media-body{
				display: inline;
			}
			padding: 5px;
		}
	}
	.card-footer{
		background-color: #ffffff;
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
	}
	.task{
		.media{
			padding-top: 9px
		}
	}
	.task-scroll{
		height: 350px;
		overflow-y: auto;
	}
	.re-task-scroll{
		height: 400px;
		overflow-y: auto;
	}
	@media (max-width: 1199.98px) {
		.person{
			padding-right: 15px;
			padding-left: 15px;
			margin-bottom: 20px;
		}
		.col-padd{
			margin-bottom: 15px;
		}
		.day-off-text{
			padding: 20px;
			.media-body{
				display: flex;
			}
		}
	}
	@media (max-width: 1100.98px) {
		.colon-order{
			order:-1;
		}
		.col-padd{
			padding: 0 15px;
		}
		.day-off-text{
			.media-body{
				display: inline;
			}
			.check-btn{
				margin-right:15px;
			}
		}
	}
	@media (max-width: 767.98px) {
		.day-off-text{
			.media-body{
				display:flex;
			}
		}
		.person {
			padding-right: 5px;
			padding-left: 5px;
		}
		.col-padd{
			padding: 0 5px;
		}
		.card-body-scroll,.news-scroll , .news-scroller, .task-scroll,.re-task-scroll {
			height: auto;
		}
	}

}



