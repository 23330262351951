
.back-row{
	height: 75px;
	background-color: #ffffff;
	border-radius: 20px;
	.btn{
		margin: 19px 23px;
	}
}
.card-header{
	min-height: 70px;
	background-color: #ffffff;
	padding: 20px 35px;
	h2{
		font-size: 15px;
		font-weight: 600;
		color: $dark-blue;
	}
}
.tasks-row{
	.info-text{
		h3{
			font-size: 22px;
			color: $dark-blue;
		}
		h4{
			font-size: 19px;
			color: $light-blue;
		}
		p{
			font-size: 14px;
			color: $dark-blue;
		}
	}
	.task-hour{
		.material-icons{
			font-size: 35px;
		}
		span{
			font-size: 25px;
			color: #3b7cff;
		}
		.left-time{
			color: #f36f6f;
		}
		p{
			font-size: 15px;
			color: $dark-blue;
			margin-bottom: 0px;
			font-weight: 600;
		}
		p:last-child{
			font-size: 10px;
			color: #c8d0d9;
		}
	}
} 

.card-body-scroll{
	height: 140px;
	overflow-y: auto;
	overflow-x: hidden;
}
.note-row{
	.note-text{
		font-size: 14px;
		color: #9facbd;
			}
}
.staff-text{
	h3{
		font-size:14px;
		color: $dark-blue;
		margin-bottom: 0px;
	}
	p{
		font-size: 12px;
		color: c8d0d9;
	}
}
