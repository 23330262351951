.fadeInLoadUp{
	animation-name: fadeInLoadUpAnimation;
	animation-duration: 0.4s;
}

@keyframes fadeInLoadUpAnimation {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity:1;
        transform: translateY(0px);
    }
}

.fadeInLoad{
	animation-name: fadeInLoadAnimation;
	animation-duration: 0.4s;
}

@keyframes fadeInLoadAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity:1;
    }
}

.fadeInLoadLeft {
    animation-name: fadeInLoadLeftAnimation;
    animation-duration: 1s;
}

@keyframes fadeInLoadLeftAnimation {
    from {
        opacity: 0;
        transform: translateX(40px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.fadeInLoadRight {
    animation-name: fadeInLoadRightAnimation;
    animation-duration: 1s;
}

@keyframes fadeInLoadRightAnimation {
    from {
        opacity: 0;
        transform: translateX(-40px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
