.full-width {
	width: 100%;
}

.box-shadow {
	box-shadow: 0 10px 20px rgba(59, 124, 255, .2);
}

.text-secondary-color {
	color: $secondary-color;
}

.loading-block{
	text-align: center;
}

.make-inline-b {
	display: inline-block;
}

.profile-pic {
	border-radius: 50%;
}
.card{
	box-shadow: 0 2px 4px rgb(239, 242, 247);
	border: 1px solid #F5F6F5;
	.card-header{
		border-bottom: 1px solid #F5F6F5;
	}
	.card-footer{
		border: 1px solid #F5F6F5;
	}
}
.material-icons{
	vertical-align: middle;
}
.container-fluid{
	padding:0 5px 5px 5px;

}

.align-right{
	margin-left: auto;
}

@media (max-width:575.98px){
	.container-fluid{
		padding: 0px;
	}
}
