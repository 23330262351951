.sidebar {
	background-color: $light-gray;
	max-width: 240px;
	min-width: 240px;
	transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;

	.dashboard-menu {
		padding-left: 15px;
		list-style: none;

		li {
			margin-bottom: 10px;

			a {
				display: block;
				color: $light-blue;
				font-size: 14px;
				padding: 12px 0;
			}

			a.active {
				color: #4b306a;
				transition: color 0.3s;
				font-weight: bold;

				.material-icons {
					color: inherit;
				}
			}

			&>button {
				width: 100%;
				padding: 0;
				text-align: left;

				&:hover,
				&:focus {
					text-decoration: none;
				}

				&>span {
					display: block;
					color: $light-blue;
					font-size: 14px;
					padding: 12px 0;
				}
			}

			span {

				font-size: 14px;
			}

			ul {
				margin-top: 10px;
				list-style: none;
			}

			a:hover {
				text-decoration: none;
			}

			.task-link {
				display: inline;
				vertical-align: sub;
			}
		}

		&__collapsable {
			padding: 0;
			position: relative;

			img {
				position: absolute;
				top: 28px;
				right: 8px;
				transform: translateY(-50%);
			}
		}

		img:hover {

			cursor: pointer;
		}

		.collapsed {
			transform: rotate(0deg);
			transition: all .3s ease-out;
		}

		.arrow-img {
			transition: all .3s ease-out;
			transform: rotate(90deg);
		}
	}

	.material-icons {
		margin-right: 10px;
		font-size: 30px;
		vertical-align: bottom;
		color: $light-blue;
	}

	.nav-links {
		display: block;
	}

	.pannel-logout {
		margin-left: 20px;
		margin-top: 12px;

		span {
			margin-left: 20px;
			margin-top: 8px;
			font-size: 14px;
			color: $dark-blue;
			margin-left: 20px;
		}

		.btn {
			padding: 0;
			color: $light-blue;
			font-size: 14px;
		}

		.btn:hover,
		.btn:focus,
		.btn:active,
		.btn:active:focus {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
		}

	}

	.sidebar-left {
		display: inline-block;
		width: 240px;
		background-color: #ffffff;
		padding: 15px 18px;

		.sidebar-brand {
			display: inline-block;
			margin-left: 18px;
			color: $dark-blue;

			img {
				vertical-align: middle;
			}

			h4 {
				display: inline-block;
				margin: 0px 4px 0px 14px;
				vertical-align: middle;
				font-weight: normal;
			}
		}
	}

}

.toggled {
	margin-left: -240px;
}

@media (max-width: 991.98px) {
	.sidebar {
		margin-left: -240px;
	}

	.toggled {
		margin-left: 0px;
	}
}
