.login-form,.public-form{
	margin-top: 100px;
	padding-bottom: 50px;
	.inputs-wrapper{
		box-shadow: 0 40px 70px rgba($light-blue, 0.2);
		font-size: 14px;
	}
	.form-group{
		padding: 15px 30px;
		background-color: #f8f9fb;
		&:first-child{
			margin-bottom: 0;
		}
	}
	.form-control{
		padding-left: 0;
		background-color: transparent;
		border:none;
		// border-bottom: 1px solid rgb(182, 191, 196);
		border-radius: 0px;
	}
	.bottom-actions{
		margin: 40px 0;
		justify-content: space-between;
		font-size: 14px;
		a{
			color: $dark-blue;
		}
	}
	.btn-dark{
		width: 180px;
		height: 75px;
		font-size: 14px;
		text-transform: uppercase;
	}
	@media (max-width:575.95px)  {
		margin-top: 40px;
		padding-bottom: 20px;
		.bottom-actions{
			margin: 20px 0;
		}
		.btn-dark{
			width: 120px;
			height: 45px;
		}
	}
}
.floating-label{
	.form-label{
		color: #808080;
	}
}
.roles-page{
	.btn-group-toggle{
		.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active{
			color: $light-green;
			background-color: #ffffff;
			border-color: $light-green;
		}
		.btn-outline-success:hover {
			color: $light-green;
			background-color: #ffffff;
			border-color: $light-green;
		}
	}
	.form-control:not(.is-invalid){
		border-color: $primary-blue;
	}
}

@keyframes flash-bg{
	0% {
        background-color: #ffffff;
    }
    30% {
        background-color: rgb(224, 255, 231);
    }
    80% {
        background-color: rgb(224, 255, 231);
    }
    100% {
		background-color: #ffffff;
    }
}

.select-input.is-invalid{
	// border: 1px solid #dc3545;
}

.select-input.is-invalid ~ .invalid-feedback{
	display: block;
}

.create-company-form, .edit-company-form, .create-client-form, .create-module-form, .form__short{
	max-width: 600px;
	.form-group{
		margin-bottom: 1.5em;
	}
}

//.employee-dropdowns {
//	margin-bottom: 10px;
//	border-bottom: 2px solid #ececec;
//}

.employee-data-header {
	max-width: 620px;
	padding-top: 10px;
	border-top: 2px solid #ececec;
	margin-bottom: 10px;
}

.users-fileter-dropdown{
	.dropdown-menu{
		padding: 10px;
	}
}

.filter-form{
	form{
		display: flex;
		::placeholder {
			color: #C8D0D9;
		}
		.form-control{
			flex-basis: 150px;
			background-color: #F5F8FC;
			border: none;
			border-radius: 0;
			font-size: 14px;
			padding: 5px 15px;
			line-height: 1em;
			height: 30px;
			margin: 0 2px;
			flex-grow: 1;
			&:first-child{
				border-top-left-radius: 25px;
				border-bottom-left-radius: 25px;
			}
		}
		button{
			padding: 0px;
			height: 30px;
			margin: 0 2px;
			display: block;
			flex-grow: 0;
			flex-basis: 100px;
		}
	}
}

.business-unit-form, .create-role-form, .edit-role-form, .user-form, .form__loading{
	position: relative;
	.loading-overlay{
		position: absolute;
		z-index: 11;
		background-color: rgba(255,255,255, 0.8);
		top: -10px;
		left: 0;
		right: 0;
		bottom: -10px;
		display: flex;
		justify-content: center;
		align-items: center;
		h5{ font-size: 14px; }
	}
	.permissions-row{
		padding: 15px 0;
		background-color: #F5F8FC;
		height: 100%;
		h4{border-bottom: 1px solid $dark-blue;color: $dark-blue;font-size: 22px;margin-bottom: 10px; padding-bottom: 5px;}
	}
}

.profile-image-picker {
	label{
		display: block;
		min-width: 200px;
		width: 100%;
	}
	input[type="file"] { display: none;}

	.image-uploader-bg {
		padding: 10px 5px;
		width: 100%;
		background-color: #EFEFEF;
		display: block;
		border: 1px dashed #BCBCBD;
		text-align: center;
		position: relative;
		img{
			width: 140px;
		}
	}
}

button.picky__input {
	overflow: hidden;
}

.picky__placeholder{
	overflow: hidden;
	white-space: nowrap;
	max-width: 95%;
	display: block;
}
.account-form #input_client_id{
	z-index: 5;
}

.accordion-custom {
	background-color: unset;
	border: 0;
	width: 100%;
	text-align: left;

	&:focus {
		outline: none;
	}
}
.counter-spacing {
	margin: 35px auto;
}
.counter-spacing:first-child {
	margin: 5px auto;
}
.Resume_Image{
	padding: 20px;
	margin-bottom: 20px;
	label{
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #ddd;
		width: 160px;
		height: 160px;
		border-radius: 50%;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

}

.Advanced-Search{
	input{
		margin-right: 10px;
		padding: 5px 10px ;
		border: 1px solid #eee;
	}
	padding: 10px 0;
}