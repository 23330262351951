.employee-form {
	.css-26l3qy-menu {
		z-index: 99999;
	}

	&.employee-data {
		border: 2px solid #ececec;
		padding: 14px;
		margin-bottom: 25px;
	}

	.calendar {
		.SingleDatePicker {
			display: block;

			.SingleDatePickerInput__withBorder {
				width: 100%;
				min-height: 38px;

				.SingleDatePickerInput_calendarIcon {
					position: absolute;
					z-index: 1;
					right: 0;
					top: 0;
				}

				.DateInput {
					width: 100%;

					input {
						font-size: 1rem;
						font-weight: 400;
						line-height: 1.5;
						height: 38px;
						color: hsl(0, 0%, 50%);
					}
				}
			}
		}
	}
}
.em-ta .rt-tr .rt-resizable-header:last-of-type {
	text-align: right;
	padding-right: 25px;
}
.em-ta .rt-tr .rt-resizable-header:first-of-type {
	display: none;
}
.em-ta .rt-tbody .rt-tr .rt-td:first-of-type {
	display: none;
}

.em-ta .rt-table .-filters .rt-tr .rt-th:first-of-type {
	display: none;
}
.employee-seacrh-general {
	justify-content: end;
}

.dropdown-item-resumes {
	color: rgb(19, 132, 150);
	display: flex;
	align-items: center;
	gap: 5px;
}
