.PdfModal{
    height: 90vh !important;
    width: 1000px !important;
    background-color: rebeccapurple;
    @media (min-width: 1200px) { 
        max-width:1000px !important;
     }
    .modal-content{
        height: 100%;
        width: 100%;
        @media (min-width: 1200px) { 
            width:1000px !important;
         }

    }
    .modal-body{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}