.single-client{
	.client-information{
		h3{
			font-size: 22px;
			font-weight: 600;
			color: $dark-blue;
		}
		.client-contact{
			font-size: 14px;
		}
		h4{
			font-size: 14px;
			color: $dark-blue;
		}
	}
	&__information{
		padding-left:35px;
		padding-right:15px;
	}

	&__join{
		font-size: 14px;
	}
	&__company{
		font-size: 15px;
		font-weight: bold;
		color: #9FACBD;
	}
	&__company-info{
		font-size: 14px;
		color: $dark-blue;
	}
	.single-employee__financial-info{

		&__price-nr--winnings{
			font-size: 25px;
			color: $light-green;
			font-weight:600;
		}
		&__price-txt--winnings{
			font-size: 10px;
			color: $secondary-color
		}
		&__price-nr--costs{
			font-size: 25px;
			color: $red;
			font-weight:600;
		}
		&__price-txt--costs{
			font-size: 10px;
			color: $secondary-color
		}
	}
	table{
		th{
			border: none;
		}
		td{
			border-top: 1px solid #F5F6F5;
			font-size: 14px;
			color: $dark-blue;
		}
		.material-icons{
			font-size: 19px;
		}
	}
	.list-group-item {
		color: $light-blue;
		border: none;

		&.odd {
			background-color: $light-gray;
		}
	}
	@media (max-width:575.98px) {
		&__media-information{
			display: inline;
			text-align: center;
		}
		&__join{
			text-align: center;
		}
		.client-information{
			h3{
				margin-top: 15px;
			}
		}
	}
}
.cl-ta .rt-tr .rt-resizable-header:last-of-type {
	text-align: right;
	padding-right: 25px;
}
.cl-re-ta .rt-tr .rt-resizable-header:first-of-type {
	display: none;
}
.cl-re-ta .rt-tbody .rt-tr .rt-td:first-of-type {
	display: none;
}
.cl-re-ta .rt-table .-filters .rt-tr .rt-th:first-of-type{
	display: none;
}

// .remove-id .rt-tr .rt-resizable-header:first-of-type, .remove-id .rt-thead .rt-tr .rt-th:first-of-type, .remove-id .rt-tbody .rt-tr .rt-td:first-of-type,
// .remove-id .-filters .rt-tr .rt-th:first-of-type {
// 	display: none;
// }
.center-action .rt-thead .rt-tr .rt-th:last-of-type {
	text-align: center;
}
.table-style .unique-style-table .rt-tbody .-odd {
    background-color: #FFF;
}
.table-style .unique-style-table .rt-tbody .rt-tr .rt-td{
	text-align: center;
}
.table-style .unique-style-table .rt-thead .rt-tr .rt-th{
	text-align: center;
}
.table-style .unique-style-table .rt-tbody .rt-tr .rt-td:nth-child(2) {
	text-align: left;
}
.unique-style-table .rt-tbody .rt-tr-group {
    border-bottom: none;
}
.unique-style-table .rt-thead .rt-th, .unique-style-table .rt-tbody .rt-th  {
	border-right: none;
}
.unique-style-table .pagination-bottom {
	display: none;
}
.unique-style-table .allells {
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 50%;
	left: 0px;
	top: 2.5px;
}
.unique-style-table .ellipse0 {
	background-color: #4B306A;
}
@for $i from 0 through 9 {
	.unique-style-table .ellipse#{$i + 1} {
	
			background-color: rgba(75, 48, 106, 0.9 - $i /10);
	}
  }
.unique-style-table .ellired0 {
	background-color: #F45252;
}
@for $i from 0 through 9 {
	.unique-style-table .ellired#{$i + 1} {
	
			background-color: rgba(244, 82, 82, 0.9 - $i /10);
	}
  }

  .unique-style-table .elliblue0 {
	 background-color: #4879F5;
  }
  @for $i from 0 through 9 {
	.unique-style-table .elliblue#{$i + 1} {
	
			background-color: rgba(72, 121, 245, 0.9 - $i /10);
	}
  }
  .unique-style-table .elligreen0 {
	  background-color: #20C745;
  }
  @for $i from 0 through 9 {
	.unique-style-table .elligreen#{$i + 1} {
	
			background-color: rgba(32, 199, 69, 0.9 - $i /10);
	}
  }

.table-gray .rt-thead .rt-th:last-child, .table-gray .rt-tbody .rt-td:last-child {
	background-color: rgba(75, 48, 106, 0.1);
}
.table-red .rt-thead .rt-th:last-child, .table-red .rt-tbody .rt-td:last-child {
	background-color:  rgba(244, 82, 82, 0.1);
}
.table-green .rt-thead .rt-th:last-child, .table-green .rt-tbody .rt-td:last-child {
	background-color:  rgba(32, 199, 69, 0.1);
}
.table-blue .rt-thead .rt-th:last-child, .table-blue .rt-tbody .rt-td:last-child {
	background-color:  rgba(72, 121, 245, 0.1)
}
.table-style h6 {
	font-weight: bold;
	margin-bottom: 25px;
}
.unique-dashboard {
	margin: 30px auto;
}
// .unique-style-table .-pagination {
// 	display: none;
// }
@media (max-width:575.98px) {
.respnsiveHeader {
	font-size: 1.3rem;
}
}