.calendar-hours{ 
	&-sidebar{
		color: $secondary-color;
		font-size: 14px;
		border-right: 1px solid $secondary-color;
		position: relative;
		span{
			position: absolute;
			top: 50%;
			left: 25%;
			transform: translateY(-50%);
		}
	}
	.rct-header-root{
		background-color: #F5F8FC;
		border: 1px solid $secondary-color;
		.rct-dateHeader{
			background-color: #F5F8FC;
			border: 0.5px solid $secondary-color;
			color: $secondary-color;
			font-size: 14px;
		}
		.rct-calendar-header{
			
			border: none;
		}
	}
	.react-calendar-timeline {
		border: none;
		.rct-hl-even{
			border-bottom: 1px solid $secondary-color;
		}
		.rct-sidebar-row{
			border-bottom: 1px solid $secondary-color;
			border-left: 1px solid $secondary-color;
			color: $dark-blue;
			font-size: 14px;
			&-odd{
				background-color: #F8F9FB!important;
			}
		}
		.rct-hl-odd{
			border-bottom: 1px solid $secondary-color;
			background-color: #F8F9FB;
		} 
		.rct-day-6, .rct-day-0{
			background-image: url(/images/calendar-back.jpg)!important;
			background-size: cover!important;
		}
		.rct-item{
			border: none!important;
		}
	}
	.custom-group{
		.media-body{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
    }
    }

}
.calendar label {
	position: absolute;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
	top: 0;
    left: 0;
    padding: calc(0.375rem + 1px) calc(0.75rem + 1px);
    pointer-events: none;
	
}
.form-check label {
	position: relative;
	overflow: hidden;
	padding: 0;
    pointer-events: auto;
}
.calendar .floating-label .label-focued {
	background-color: #fff;
	top: calc( -1.5rem + 1px + 0.65625rem );
	padding: 0 0.7ch 0 0.7ch;
	left: calc(0.75rem + 1px - 0.7ch);
	border-radius: 0.25rem;
	font-size: 0.875rem;
}