.add-btn {
	border-radius: 50%;
	padding: 1px;
	width: 30px;
	height: 30px;
	box-shadow: 0 40px 70px rgba($light-blue, 0.2);
}

.new-btn {
	border-radius: 20px;
	padding: 1px;
	width: 112px;
	height: 30px;
	box-shadow: 0px 0px 65px 20px rgba(137, 152, 172, 0.4);
	font-size: 11px;
}

.btn-primary {
	background-color: #4b306a;
	border-color: #4b306a;
}

.btn-primary:hover {
	background-color: #675082;
	border-color: #675082;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
	background-color: #675082;
	border-color: #675082;
}

.edit-btn {
	border-radius: 50%;
	padding: 1px;
	width: 24px;
	height: 24px;
	background-color: #9facbd;
	border: none;
}

.delete-btn {
	border-radius: 50%;
	padding: 1px;
	width: 24px;
	height: 24px;
	background-color: #f36f6f;
	border: none;
}

.check-btn {
	border-radius: 50%;
	padding: 1px;
	width: 24px;
	height: 24px;
	background-color: #79c10c;
}

.read-btn {
	border-radius: 20px;
	padding: 1px;
	width: 92px;
	height: 24px;
	font-size: 12px;
	background-color: $dark-blue;
	border: $dark-blue;
}

.task-btn {
	border-radius: 20px;
	padding: 1px;
	width: 84px;
	height: 24px;
	font-size: 12px;
	background-color: #fb745b;
	border: #fb745b;
}

.completed-btn {
	border-radius: 20px;
	padding: 1px;
	width: 84px;
	height: 24px;
	font-size: 12px;
	background-color: $dark-blue;
	border: $dark-blue;
}

.create-btn {
	border-radius: 20px;
	padding: 1px;
	width: 111px;
	height: 24px;
	font-size: 12px;
	background-color: $dark-blue;
	border: $dark-blue;
}

.back-btn {
	border-radius: 20px;
	padding: 0px;
	width: 101px;
	height: 30px;
	font-size: 12px;

	.material-icons {
		vertical-align: middle;
	}
}

.calendar-day {

	&-aprove {
		background-color: $light-green;
		border-radius: 20px;
		padding: 0px;
		width: 70px;
		height: 24px;
		font-size: 12px;
		border: none;
		margin-top: 10px;
	}

	&-deny {
		background-color: $red;
		border-radius: 20px;
		padding: 0px;
		width: 70px;
		height: 24px;
		font-size: 12px;
		border: none;
		margin-left: 5px;
		margin-top: 10px;
	}

	&-expand {
		background-color: $dark-blue;
		border-radius: 20px;
		padding: 0px;
		width: 70px;
		height: 24px;
		font-size: 12px;
		border: none;
		margin-left: 5px;
		margin-top: 10px;
	}
}

.btn-danger {
	border-color: $light-red;
}

.btn-danger.btn-outline-danger {
	background-color: transparent;

	&:hover {
		background-color: $light-red;
	}

}

.btn-success {
	background-color: $light-green;
	border-color: $light-green;
}

.btn-success.btn-outline-success {
	background-color: transparent;
	color: $light-green;
	border-color: $light-green;

	&:hover {
		color: #ffffff;
		background-color: $light-green;
	}
}

.btn-roundet {
	border-radius: 35px;
}

.btn-sm {
	font-size: 14px;

	.material-icons {
		font-size: 22px;
	}
}

.btn-success:hover,
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
	border-color: darken($light-green, 5);
	background-color: darken($light-green, 5);
}

.btn-dropshadow {
	box-shadow: 0 5px 10px rgba(59, 124, 255, 0.2);
}

.btn-dropshadow.btn-success {
	// box-shadow: 0px 5px 10px rgba(121,193,12,0.2);
}

.user-filte-form-wrapper {
	.btn-outline-danger {
		margin-left: 5px;
		font-size: 12px;
		border-radius: 19px;
	}

	.btn-success {
		border-radius: 19px;
		font-size: 12px;
	}
}

.btn-no__background {
	background-color: transparent;
	border: none;
	color: black;
	// display: none;
}

.show-hide-password {
	position: absolute;
	top: 7px;
	right: 0px;
	min-width: 0px;
	background-color: transparent;
	border: none;
}

.show-hide-password svg {
	width: 20px;
	height: 17px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	opacity: 1;
}

.header-actions {
	text-align: right;
    padding-right: 20px;
}
.uniq-action {
    padding-right: 70px;

}
.btn-plus {
	border-radius: 2.375rem;
    font-size: 23px;
	padding: 0px 0.65rem;
}

.btn-100{
	width: 100px;
}