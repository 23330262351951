.fadeInLoadLeft {
	animation-name: fadeInLoadLeftAnimation;
	animation-duration: 0.8s;
}

@keyframes fadeInLoadLeftAnimation {
	from {
		opacity: 0.2;
		transform: translateX(60px);
	}

	to {
		opacity: 1;
		transform: translateX(0px);
	}
}

.public-page {
	.container-fluid {
		padding: 0 0px;
	}
}

.login-form-wrapper {
	margin: 110px auto 0;
	max-width: 530px;
	padding: 0 14px;

	.logo-section {
		margin-bottom: 100px;

		a {
			display: inline-block;
			color: $dark-blue;
			text-decoration: none;
			width: 100%;

			img {
				vertical-align: middle;
			}

			h1 {
				display: inline-block;
				// margin-left: 15px;
				font-weight: normal;
				font-size: 40px;
				vertical-align: middle;
			}
		}
	}

	.form-control {
		border-bottom: 1px solid rgba(59, 124, 255, 0.2);
	}

	h2 {
		font-size: 46px;
		font-weight: lighter;
		color: $light-blue;
		margin-bottom: 15px;

		span {
			color: $dark-blue;
		}
	}

	.lead {
		color: $light-blue;
		font-size: 16px;
	}

	.bottom-actions {
		label {
			color: $light-blue;
		}
	}

	@media (max-width:575.95px) {

		margin: 30px auto 0;

		.logo-section {
			margin-bottom: 30px;

		}

		h2 {
			font-size: 25px;
		}
	}
}

.right-col-img {
	overflow: hidden;

	.right-image-cover {
		max-height: 100vh;

		img {
			max-height: 100%;
			max-width: 100%;
		}
	}

	@media(max-width:991.95px) {
		display: none;
	}
}
