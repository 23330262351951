body,
html {
	font-family: "Montserrat", sans-serif;
}

.record-primary {
	display: inline-block;
	width: 80px;
	border-radius: 20px;
	background-color: #007bff;
}

.btn-icons {
	padding-top: 3px !important;
}

.SingleDatePicker_picker {
	z-index: 9 !important;
}

.flex-between {
	display: flex;
	justify-content: space-between;
}
.flex-align-center {
	display: flex;
	align-items: center;
}
input {
	outline: none;
}
.innerTableSearch {
	overflow: hidden;
	display: flex;
	input {
		width: 90%;
	}
}

.skills-column {
	margin-top: 2px;
	display: inline-block;
}
.skills-column span {
	background-color: #feefe1;
	border: 1px solid #ccc;
	color: #000;
	font-weight: 700;
	padding: 3px;
	border-radius: 10px;
	margin-right: 11px;
	font-size: 11px;
	display: inline-block;
	text-align: center;
}
.outer-Table-Search {
	width: 31px;
	transition: width 0.4s ease-in-out;
}

.innerTableSearch input {
	// display: none;
	width: 0;
	padding: 0 !important;
	transition: width 0.4s ease-in-out;
}
.outer-Table-Search input {
	width: 0;
	padding-left: 0 !important;
}
.outer-Table-Search label {
	cursor: pointer;
}
.innerTableSearch .open-search {
	display: block;
	width: 60%;
	transition: width 0.4s ease-in-out;
}
.outer-Table-Search .open-search {
	width: 300px;
	transition: width 0.5s ease-in-out;
}
.open-parent-search {
	transition: width 0.4s ease-in-out;
	width: 100%;
}
.open-parent-search-outer {
	transition: width 0.4s ease-in-out;
	width: 271px;
	padding: 0px 4px;
}
@media (max-width: 501px) {
	.open-parent-search-outer {
		width: 260px;
	}
}
@media (max-width: 590px) {
	.direct-icon {
		direction: rtl;
	}
}
.option-filter {
	display: flex;
	justify-content: space-between;
}
.unique-filter-table .-filters {
	position: relative;
	top: -30px;
	left: 0;
}
.unique-filter-table .innerTableSearch {
	width: 20px;
	margin: auto 80px;
	height: 22px;
	transition: width 0.4s ease-in-out;
}
.search-icon-contacts .innerTableSearch {
	margin: auto 100px;
}
.unique-filter-table .innerTableSearch label {
	cursor: pointer;
}
.resumes-employees .unique-filter-table .open-search-filter {
	width: 53%;
}
.unique-filter-table .open-search-filter {
	width: 150px;
	transition: width 0.4s ease-in-out;
}
.unique-filter-table .innerTableSearch input {
	width: 86%;
}
.unique-filter-table .option-filter {
	margin-bottom: 0;
}

// .menu-appear {
// 	position: relative;
// 	z-index: 999;
// }
// .unique-appeare {
// 	position: absolute;
// 	width: 500px;
// 	z-index: 9999;
// }
// .unique-appeare .menu-appear div:last-child {
// 	position: absolute;
// 	z-index: 99999;
// }
.unique-appeare select {
	padding: 7px 5px;
	background-color: transparent;
	border: 1px solid #bdbdbd;
	width: 150px;
	border-radius: 6px;
}
.unique-appeare select option {
	background-color: #fff;
	padding: 7px 5px;
	font-size: 15px;
}
.unique-appeare select option:hover {
	background-color: #e2f4f9;
}
.skills-dropdown h4 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	color: #999;
}
.skills-menu {
	height: 0;
	opacity: 0;
	transition: all 0.4s ease-in-out;
	list-style: none;
}

.show-skills {
	height: auto;
	opacity: 1;
	transition: all 0.5s ease-in-out;
	padding: 0;
	// animation: height 1 1s ease-in-out;
	// animation-delay: 0;
	// animation-fill-mode: forwards;
}
@keyframes height {
	0% {
		height: 0;
		visibility: hidden;
	}
	100% {
		height: 100%;
		visibility: visible;
	}
}
.skills-dropdown .material-icons {
	transform: rotate(0deg);
	transition: all 0.4s ease-in-out;
}

.show-skills-icon .material-icons {
	transform: rotate(-90deg);
	transition: all 0.4s ease-in-out;
}
.show-skills-icon h4 {
	color: #000;
	transition: color 0.4s ease-in-out;
}
.skills-menu li {
	margin: 15px auto;
}

.search-icon-contacts .rt-thead {
	font-size: 12px;
}
@media (max-width: 991.98px) {
	.dashboard-page .main {
		min-width: auto !important;
	}
}
