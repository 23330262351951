.dashboard-page{
	display: flex;
	margin: 0 auto;
	width: 100%;
	overflow: hidden;
	padding: 0;
	.main{
		position: relative;
		background-color: #f9fafc;
		width: 100%;
		min-height: 100vh;
		min-width: 0;
		transition: margin-left .3s ease-in-out,left .3s ease-in-out,margin-right .3s ease-in-out,right .3s ease-in-out;
	}
	.breadcrumb{
		height: 48px;
		border-radius:0px;
		margin-bottom: 0;
		label{
			color: $dark-blue;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 600;
		}
		.breadcrumb-item{
			color: $light-blue;
			font-size: 13px;
			
		}
		.breadcrumb-item + .breadcrumb-item::before{
			content: ">";	
		}
	}
	
	.dashbord-content-wrapper{
		padding: 0 15px;
	}

	@media (max-width: 991.98px){
		.main {
			overflow-y: hidden;
			width: 100vw;
			min-width: 100vw;
		}
		
		.body-cover{
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.3);
			position: absolute;
			z-index: 10;
		}
	}
	
}

.top-actions-row{
	display: flex;
	margin: 15px 0;
	background-color: #fff;
	padding: 20px 35px;
	border-radius: 15px;
	box-shadow: 0 4px 8px #EFF2F7;
}

.card{
	border-radius: 15px;
	box-shadow: 0 4px 8px #EFF2F7;
}
