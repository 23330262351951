.table-style {
	.card-body {
		padding-top: 0;
	}

	.bar-below {
		height: 3px;
		background-color: #007bff;
	}

	.cell-position {
		position: relative;
		text-align: center;
	}

	.col-padd {
		padding: 0 5px;
	}

	.card-header {
		padding: 15px;
		min-height: auto;

		.back-btn {
			padding: 5px;
		}
	}

	.form-input {

		.form-control {
			font-size: 14px;
			height: 30px;
			background-color: #F5F8FC;
			padding: 5px 15px;
		}

		.radius-form {
			padding-right: 3px;

			input {
				border-bottom-left-radius: 20px;
				border-top-left-radius: 20px;
			}
		}

		input,
		select {
			border: none;
		}

		&--search {

			font-size: 12px;
			width: 100px;
			border-bottom-right-radius: 20px;
			border-top-right-radius: 20px;

			.material-icons {
				font-size: 14px;
				vertical-align: middle;
			}
		}
	}

	.nav-item {
		.dropdown-toggle::after {
			display: none;
		}

		.dropdown-item:active,
		.dropdown-item:hover {
			background-color: transparent;
			color: #000;
		}

		.down {
			margin-top: 4px;

			.dropdown-toggle {
				border-radius: 50%;
				padding: 1px;
				width: 30px;
				height: 30px;
				box-shadow: 0 40px 70px rgba($light-blue, 0.2);
			}

			.dropdown-menu {
				left: -60px !important;
				border: none;
				background-color: transparent;
			}

			.dropdown-item {
				width: 112px;
				margin-top: 20px;
			}

		}
	}

	.ReactTable {
		border: none;

		.rt-resizable-header-content {
			font-size: 12px;
			color: #4b306a;
		}

		.rt-td {
			font-size: 14px;
			color: $dark-blue;
		}

		.rt-thead {
			border: none;

			.-sort-asc {
				box-shadow: inset 0 3px 0 0 rgba(59, 124, 255, 1);
			}

			.-sort-desc {
				box-shadow: inset 0 -3px 0 0 rgba(59, 124, 255, 1);
			}

		}

		.rt-tbody {
			.-odd {
				background-color: #F8F9FB;
			}
		}

		.-pagination {
			.-btn {
				color: #ffffff;
				background-color: #4b306a;
			}

			.-btn:hover {
				background-color: #4b306a;
			}
		}

		.coloumn-color {
			color: #9FACBD;
		}

		.project-hours {
			color: #3b7cff;
			font-size: 18px;
			font-weight: 600;
		}

		.info-icon {
			display: inline-block;
			width: 1.4375rem;
			height: 1.4375rem;
			background-color: #3b7cff;
			border-radius: 50%;
			margin-right: .5rem;
		}

		svg {
			color: #ffffff;
		}
	}

	.material-icons {
		font-size: 19px;
	}

	@media (max-width:1199.95px) {
		.order-3 {
			order: 1;
		}
	}

	@media (max-width:767.95px) {
		.form-input {
			&--search {
				border-radius: 20px;
				width: 100%;
				padding: 10px;
			}

			.radius-form {
				padding: 0 5px;
				margin-bottom: 15px;

				input {
					border-radius: 0%;
				}
			}
		}

		.ReactTable {
			.-pagination {
				display: inline;
			}
		}
	}
}

.ReactTable .rt-thead .rt-tr {
	text-align: left;
}

.ReactTable .rt-tbody {
	padding-bottom: 40px;
}

.table-actions-wrapper {
	justify-content: flex-end;

	.btn {
		margin: 0 5px;
		height: 35px;
		width: 35px;
		padding: 0px;

		.material-icons {
			font-size: 19px;
		}
	}

	&>.btn:not(:last-child):not(.dropdown-toggle),
	&>&:not(:last-child)>.btn {
		border-radius: 25px;
	}

	&>.btn:not(:first-child),
	&>&:not(:first-child)>.btn {
		border-radius: 25px;
	}
}

.rt-table {
	.rt-th {
		.custom-control-label {
			color: $primary-blue;
			vertical-align: middle;
			font-size: 18px;

			&:after,
			&:before {
				top: 0.15rem;
			}
		}
	}
}

.exports-table {
	.badge {
		&.failed {
			background-color: $light-red;
		}

		&.completed {
			background-color: $light-green;
		}

		&.in_progress {
			background-color: $dark-blue;
		}
	}
}

.audits-details-section {
	.inner-details {
		padding: 5px;

		h5 {
			padding: 5px 0;
			color: #3b4859;
			border-bottom: 1px solid #cfd9e6;
		}

		background-color: #F5F8FC;
		height: 100%;

		.list-item-name {
			font-size: 16px;
		}
	}
}
